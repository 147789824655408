<template>
  <div class="AdvanceManagement">
    <div class="Advance-title">
      <p>预付贷款</p>
    </div>
    <div class="Advance-search">
      <a-input-search placeholder="请输入买卖方名称" style="width: 300px" />
      <a-button type="primary" @click="visible = true">
        <a-icon type="plus" /> &nbsp;新增预付货款
      </a-button>
    </div>
    <div class="tabls" @click="showDrawer">
      <a-table :columns="columns" :data-source="data">
        <template slot="address" slot-scope="text, recode">
          <span>{{ recode.address }}</span>
        </template>
      </a-table>
    </div>
    <!-- 支付预付货款 -->
    <a-drawer
      placement="right"
      :closable="false"
      :visible="drawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      width="880px"
    >
      <a-row type="flex">
        <a-col :span="4">卖方：</a-col>
        <a-col :sapn="20">胶州市少礼再生资源回收站</a-col>
        <a-col :span="4" align="right">买方：</a-col>
        <a-col :sapn="20">胶州市少礼再生资源回收站</a-col>
      </a-row>
      <a-row type="flex"> 
        <a-col :span="4">支付时间：</a-col>
        <a-col :sapn="20">2021-06-08 </a-col>
        <a-col :span="7" align="right">可用贷款：</a-col>
        <a-col :sapn="20">3500000</a-col>
      </a-row>
      <a-row type="flex">
        <a-col :span="4">未到账贷款：</a-col>
        <a-col :sapn="20">0 </a-col>
        <a-col :span="9" align="right">可用税款：</a-col>
        <a-col :sapn="20">0</a-col>
      </a-row>
       <a-row type="flex">
        <a-col :span="4">未到账税款：</a-col>
        <a-col :sapn="20">0</a-col>
      </a-row>
      <a-table :columns="columns" :data-source="data">
        <template slot="address" slot-scope="text, recode">
          <span>{{ recode.address }}</span>
        </template>
      </a-table>
    </a-drawer>
    <a-modal
      title="预付贷款"
      :visible="visible"
      :footer="null"
      :width="990"
      @cancel="handleCancel"
    >
      <a-row>
        <a-col :span="3" class="left-c"
          ><span style="color: red">*</span>预付货收款方：</a-col
        >
        <a-col :span="21">
          <a-select
            show-search
            placeholder="请输入收款方关键词进行搜索选择"
            style="width: 300px"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
          >
            <a-select-option v-for="d in 5" :key="d">
              {{ d }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3" class="left-c" style="color: red"
          >*预付货款 &nbsp;&nbsp;&nbsp;</a-col
        >
        <a-col :span="21">
          <p>
            <a-input style="width: 200px" placeholder="1.000.000.00"></a-input>
          </p>
          <div class="l-flex-aligncenter">
            <span>预付总额：1,036,500.00</span>

            <div style="margin-left: 72px" class="l-flex-aligncenter">
              <a-checkbox @change="onChange"></a-checkbox>&nbsp;&nbsp;
              <span
                >代征附加税：6,500.00
                <p>&nbsp;&nbsp;增值税：30,000.00</p></span
              >
            </div>
          </div>
          <div class="tax-t">
            备注：实际支付的金额是预付总额显示的金额，若选中税费则会支付税款，可用于抵扣订单税款部分。
          </div>
        </a-col>

        <a-col :span="3">支付渠道：</a-col>
        <a-col :span="21">
          <a-radio-group v-model="value" @change="onRadio">
            <a-radio
              class="l-flex-aligncenter"
              :style="value == 1 ? radioStyle : ''"
              :value="1"
            >
              <div class="l-flex-between">
                <div class="logo">
                  <img src="../../assets/images/u7404.png" alt="" />
                </div>
                <div class="l-flex-between" style="width: 570px">
                  <p class="pt20">网关支付</p>
                  <p>
                    <span>实时到账</span>&nbsp;&nbsp;
                    <span>手续费3元</span>
                  </p>
                </div>
              </div>
            </a-radio>
            <a-radio
              class="l-flex-aligncenter"
              :style="value == 2 ? radioStyle : ''"
              :value="2"
            >
              <div class="l-flex-between">
                <div class="logo">
                  <img class="pt5" src="../../assets/images/u5315.png" alt="" />
                </div>
                <div class="l-flex-between" style="width: 570px">
                  <p class="pt20">
                    <span>余额支付</span> |
                    <span>账号：</span>
                    <span>6500000****1234</span> |
                    <span>余额：</span>
                    <span>￥1,200,000.00</span>
                  </p>
                  <p>
                    <span>实时到账</span>&nbsp;&nbsp;
                    <span>手续费3元</span>
                  </p>
                </div>
              </div>
            </a-radio>
            <a-radio
              class="l-flex-aligncenter"
              :style="value == 3 ? radioStyle : ''"
              :value="3"
              disabled
            >
              <div class="l-flex-between">
                <div class="logo">
                  <img class="pt5" src="../../assets/images/u5322.png" alt="" />
                </div>
                <div class="l-flex-between" style="width: 570px">
                  <p class="pt20">
                    <span>余额支付</span> |
                    <span>账号：</span>
                    <span>6500000****1234</span> |
                    <span>余额：</span>
                    <span>￥1,200,000.00</span>
                  </p>
                  <p>
                    <span></span>&nbsp;&nbsp;
                    <span>前往充值</span>
                  </p>
                </div>
              </div>
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row class="zs" v-if="value == 1">
        <a-col :span="6" class="left-c">选择支付账号</a-col>
        <a-col :span="18" class="pt20">
          <a-select default-value="lucy" style="width: 510px"
            ><a-select-option value="lucy"> Lucy </a-select-option></a-select
          ></a-col
        >
        <a-col :span="6" class="left-c">支付账户类型</a-col>
        <a-col :span="18" class="pt20">
          <a-select default-value="lucy" style="width: 220px"
            ><a-select-option value="lucy"> Lucy </a-select-option></a-select
          ></a-col
        >
        <a-col :span="6" class="left-c">付款银行</a-col>
        <a-col :span="18" class="pt20">
          <a-select default-value="lucy" style="width: 510px"
            ><a-select-option value="lucy"> Lucy </a-select-option></a-select
          ></a-col
        >
        <a-col :span="6" class="left-c">付款银行</a-col>
        <a-col :span="18" class="pt20">
          <a-input style="width: 510px"></a-input>
        </a-col>
      </a-row>
      <a-row class="zs" v-if="value == 2">
        <a-col :span="4"></a-col>
        <a-col :span="20" class="left-b">中再物贸云余额支付密码：</a-col>
        <a-col :span="4"></a-col>
        <a-col :span="20" class="left-b">
          <a-input style="width: 120px"></a-input>
        </a-col>
        <a-col :span="4"></a-col>
        <a-col :span="20" class="left-b">请输入6位数余额支付密码 </a-col>
      </a-row>
      <a-row class="next">
        <a-col :sapn="24" align="center">
          <a-button type="primary"> 确认支付 </a-button></a-col
        >
      </a-row>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "支付时间",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
    key: "name",
  },
  {
    title: "卖方",
    dataIndex: "age",
    scopedSlots: { customRender: "age" },
    key: "age",
  },
  {
    title: "买方",
    dataIndex: "address",
    scopedSlots: { customRender: "address" },
    key: "address 1",
  },
  {
    title: "贷款",
    dataIndex: "add",
    scopedSlots: { customRender: "add" },
    key: "address 2",
  },
  {
    title: "税款",
    dataIndex: "ess",
    scopedSlots: { customRender: "ess" },
    key: "address 3",
  },
];

const data = [
  {
    key: "1",
    name: "2021-06-10",
    age: "胶州市肌酐再生资源回收站",
    address: "江苏科能再生金属有限公司徐州分公司",
    add: 1500000,
    ess: 0,
  },
  {
    key: "2",
    name: "2021-06-10",
    age: "胶州市肌酐再生资源回收站",
    address: "江苏科能再生金属有限公司徐州分公司",
    add: 1500000,
    ess: 0,
  },
  {
    key: "3",
    name: "2021-06-10",
    age: "胶州市肌酐再生资源回收站",
    address: "江苏科能再生金属有限公司徐州分公司",
    add: 1500000,
    ess: 0,
  },
];

export default {
  components:{
  },
  data() {
    return {
      data,
      columns,
      visible: false,
      drawer: false,
      value: 1,
      radioStyle: {
        background: "rgba(255, 204, 102, 1)",
      },
    };
  },
  methods: {
    handleSearch() {},
    handleChange() {},
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
    },
    onRadio(e) {
      console.log("radio checked", e.target.value);
      this.a = e.target.value;
    },
    handleCancel() {
      console.log("Clicked cancel button");
      this.visible = false;
    },
    afterVisibleChange(val) {
      console.log("drawer", val);
    },
    showDrawer() {
      this.drawer = true;
    },
    onClose() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="less" scoped>
.AdvanceManagement {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-left: none;
  .Advance-title {
    padding: 20px 30px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 650;
  }
  .Advance-search {
    padding: 10px 40px 30px 20px;
    display: flex;
    justify-content: space-between;
  }
}
.tax-t {
  margin: 10px auto;
  font-size: 14px;
  font-weight: 400;
  color: rgba(228, 82, 0, 0.988235294117647);
}
.left-c {
  line-height: 32px;
  text-align: right;
}
.left-b {
  line-height: 10px;
  text-align: left;
}
.logo {
  width: 143px;
  height: 55px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  img {
    width: 100%;
  }
  .pt5 {
    padding: 5px 0 0 0;
  }
}
.zs {
  height: 250px;
}
.pt20 {
  padding: 0 0 0 20px;
}
/deep/ .ant-col {
  margin-bottom: 20px;
}
/deep/ .ant-radio-wrapper {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
/deep/ .ant-radio {
  &:nth-child(2) {
    width: 100%;
  }
}
</style>